<template>
<div>
<h3>Você pode solicitar as seguintes opções:</h3>
<div class="options-list" role="tablist">
    <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6" v-for="i in 5" :key="i">
																	<!--begin::Icon-->
																	<!--begin::Svg Icon | path: icons/duotune/coding/cod004.svg-->
																	<!-- <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path opacity="0.3" d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z" fill="black"></path>
																			<path d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z" fill="black"></path>
																		</svg>
																	</span> -->
																	<!--end::Svg Icon-->
																	<!--end::Icon-->
																	<!--begin::Wrapper-->
																	<div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
																		<!--begin::Content-->
																		<div class="mb-3 mb-md-0 fw-bold">
																			<h4 class="text-gray-900 fw-bolder">Correção de dados incompletos, inexatos ou desatualizados</h4>
																			<div class="fs-6 text-gray-700 pe-7 description">Você, como titular dos dados, tem o direito de obter do responsável pelo tratamento, sem demora injustificada, a retificação de dados pessoais incorretos que lhe digam respeito. </div>
																		</div>
																		<!--end::Content-->
																		<!--begin::Action-->
																		<a href="#" class="btn btn-primary px-6 align-self-center text-nowrap">Solicitar</a>
																		<!--end::Action-->
																	</div>
																	<!--end::Wrapper-->
																</div>
  </div>


</div>
  
</template>

<script>
 export default {
    data() {
      return {
        text: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `
      }
    }
  }
</script>

<style scoped>

.notice {
  margin:10px;
  height: 100px;
}
.fs-6 {
    font-size: 1.075rem!important;
}
.pe-7 {
    padding-right: 1rem!important;
}
.border-dashed {
    border-style: dashed!important;
    border-color: #e4e6ef;
}
.rounded {
    border-radius: .475rem!important;
}
.p-6 {
    padding: 1rem!important;
}
.flex-shrink-0 {
    flex-shrink: 0!important;
}
.border-primary {
    border-color: #009ef7!important;
}
.border {
    border: 1px solid #eff2f5!important;
}
.d-flex {
    display: flex!important;
}
h4{
  font-size:12px;
  color:#fff;
}
.description{
  color:#fff;
  font-weight: 400;
  font-size: 12px !important;
}

.options-list {
  width: 100%;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px;
}


.options-list::-webkit-scrollbar {
  width: 5px;
}

/* Track */

.options-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ccc;
  border-radius: 5px;
}

/* Handle */

.options-list::-webkit-scrollbar-thumb {
  
  background: #ccc;
  border-radius: 5px;
}

/* Handle on hover */

.options-list::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}


</style>